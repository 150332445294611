<template>
  <v-container fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-clipboard-text"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $t("video_carousel") }}</b>
        </h1>
      </template>
      <v-btn
        color="primary"
        rounded
        absolute
        fab
        top
        right
        @click="handleOpenAddDialog"
        data-test="MaterialB2BVideos:AddDialog:AddFile"
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-select
        background-color="#f5f5f5"
        filled
        :items="languageOptions"
        v-model="selectedLanguage"
        item-text="text"
        item-value="value"
        style="width: 92px"
        class="language-options-select"
        @change="handleSelectLanguage"
        data-test="MaterialB2BVideos:Language:ChangeLanguage"
      >
      </v-select>

      <div class="items-center" v-if="tableVideosLoading">
        <v-progress-circular
          indeterminate
          size="60"
          color="primary"
        ></v-progress-circular>
      </div>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("visible") }}</th>
            <th class="text-center">{{ $t("thumbnail") }}</th>
            <th class="text-center">{{ $tc("video", 1) }}</th>
            <th class="text-center">
              {{ $t("edit") }}
            </th>
            <th class="text-center">
              {{ $t("remove") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(video, index) in videos" :key="index">
            <td>{{ video.file }}</td>
            <td>
              <v-progress-circular
                indeterminate
                size="20"
                color="primary"
                v-if="visibleVideoLoading === video.id"
              ></v-progress-circular>
              <v-checkbox
                v-else
                class="field"
                v-model="video.visible"
                color="primary"
                outlined
                dense
                @change="(e) => handleVideoIsVisible(e, video)"
                :loading="true"
              >
              </v-checkbox>
            </td>
            <td class="text-center">
              <v-btn
                icon
                :download="video.thumbFileName"
                class="px-2 ml-1 btnDownloadFile"
                :id="video.id"
                :name="video.thumbFileName"
                color="primary"
                @click="handleDownloadThumb(video.id, video.thumbFileName)"
                :loading="fileDownloading === video.id"
                min-width="0"
                small
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <button
                class="button-plat-video"
                @click="
                  handleOpenDialogPlayVideo(video.videoId, video.videoPlatform)
                "
              >
                <font-awesome-icon :icon="['fas', 'circle-play']" />
              </button>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 btn-download"
                color="secondary"
                min-width="0"
                small
                @click="handleEditVideo(video)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                color="red"
                min-width="0"
                small
                @click="handleOpenDialogDeleteVideo(video)"
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-if="dialog" v-model="dialog" scrollable>
      <AddOrEditClip
        @successVideoRequest="handleSuccessVideoRequest"
        :videos="videos"
        :videoToEdit="videoToEdit"
        :editVideo="editVideo"
        @closeDialog="closeVideoDialog"
      />
    </v-dialog>
    <v-dialog v-if="deleteDialog" v-model="deleteDialog" scrollable>
      <DeleteConfirmationModal
        :obj="deleteObj"
        :name="deleteObj.file"
        @close="deleteDialog = false"
        @delete="deleteVideo"
      ></DeleteConfirmationModal>
    </v-dialog>
    <v-dialog v-if="videoDialog" v-model="videoDialog" scrollable>
      <VideoDialog :video="videoToOpen" />
    </v-dialog>
  </v-container>
</template>

<script>
import { LanguageTextEnum, LanguageEnum } from "@/shared/enums/LanguageEnum";
import AddOrEditClip from "./AddOrEditVideo.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import ApiService from "@/services/ApiService";
import VideoDialog from "./VideoDialog.vue";

export default {
  name: "MaterialsB2BVideoComponent",
  components: {
    AddOrEditClip,
    DeleteConfirmationModal,
    VideoDialog,
  },
  data() {
    return {
      tableVideosLoading: false,
      videoDialog: false,
      dialog: false,
      videoToOpen: null,
      deleteDialog: false,
      deleteObj: null,
      videoToEdit: null,
      editVideo: false,
      apiService: new ApiService(),
      videos: [],
      selectedLanguage: LanguageEnum.pt,
      visibleVideoLoading: null,
      languageOptions: [
        {
          text: "PT",
          value: LanguageEnum.pt,
        },
        {
          text: "EN",
          value: LanguageEnum.en,
        },
      ],
      fileDownloading: null,
    };
  },
  async created() {
    await this.getVideos();
  },
  methods: {
    handleOpenAddDialog() {
      this.dialog = true;
      this.videoToEdit = null;
      this.editVideo = false;
    },
    handleEditVideo(video) {
      this.videoToEdit = video;
      this.editVideo = true;
      this.dialog = true;
    },
    closeVideoDialog() {
      this.dialog = false;
      this.videoToEdit = null;
      this.editVideo = false;
    },
    handleOpenDialogDeleteVideo(video) {
      this.deleteObj = video;
      this.deleteDialog = true;
    },
    async handleSuccessVideoRequest() {
      this.dialog = false;
      await this.getVideos();
    },
    async deleteVideo(video) {
      await this.apiService
        .deleteRequest(`b2bmaterials/videos/${video.id}`)
        .then(async (resp) => {
          this.deleteDialog = false;
          this.$toast.success(this.$t("saved"));
          await this.getVideos();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getVideos() {
      this.tableVideosLoading = true;

      await this.apiService
        .getRequest(`b2bmaterials/videos/${this.selectedLanguage}/${true}`)
        .then((resp) => {
          this.videos = resp.content;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.tableVideosLoading = false;
    },
    async handleSelectLanguage() {
      await this.getVideos();
    },
    async handleDownloadThumb(id, name) {
      this.fileDownloading = id;

      await this.apiService
        .postRequest(`b2bmaterials/videos/thumb/${id}/download`)
        .then((resp) => {
          resp = "data:application/octet-stream;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.fileDownloading = null;
    },
    handleOpenDialogPlayVideo(videoId, videoPlatform) {
      this.videoToOpen = {
        videoId,
        videoPlatform,
      };
      this.videoDialog = true;
    },
    async handleVideoIsVisible(visible, video) {
      this.visibleVideoLoading = video.id;

      let payload = { ...video };
      delete payload.urlThumb;
      payload.visible = visible;

      let formData = new FormData();

      for (var key in payload) {
        formData.append(key, payload[key]);
      }

      await this.apiService
        .putRequest(`b2bmaterials/videos`, formData)
        .then(async (resp) => {
          this.$toast.success(this.$t("saved"));
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.visibleVideoLoading = null;
    },
  },
};
</script>

<style scoped>
.button-plat-video {
  border-radius: 4px;
  background-color: var(--primary);
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  width: 32px;
  height: 28px;
}

.button-plat-video > svg {
  color: var(--white);
}

.language-options-select {
  position: absolute;
  right: 120px;
  top: -30px;
}

.items-center {
  display: grid;
  place-items: center;
}
</style>
