<template>
  <v-alert class="ma-2" v-if="errorData" type="error">
    <ul v-for="(error, i) in errorData" :key="i">
      <li>{{ $t(error) }}</li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  name: "AlertErrorComponent",
  props: {
    errorData: Array,
  },
};
</script>

<style scoped></style>
