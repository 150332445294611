<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("add_or_edit_file") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
        <v-select
          :label="$t('index')"
          :items="videosIndexOptions"
          :rules="[required]"
          v-model="videoObject.index"
          :loading="indexLoading"
          outlined
        ></v-select>

        <v-select
          :items="languageOptions"
          :label="$t('language')"
          v-model="videoObject.language"
          :rules="[required]"
          outlined
          item-text="text"
          item-value="value"
          @change="getVideosByLanguage"
        ></v-select>

        <v-text-field
          outlined
          v-model="videoObject.title"
          :rules="[required]"
          color="primary"
          :label="$t('video_title')"
        />

        <v-text-field
          outlined
          v-model="videoObject.link"
          :rules="[required]"
          color="primary"
          :label="$t('video_code')"
        />

        <div>
          <span class="video-platform-title">{{ $t("media_plataform") }}</span>
          <v-radio-group class="mt-2" center v-model="videoObject.platform" row>
            <v-radio
              :label="$t('vimeo')"
              :value="videoPlatformEnum.VIMEO"
            ></v-radio>
            <v-radio
              :label="$t('youtube')"
              :value="videoPlatformEnum.YOUTUBE"
            ></v-radio>
          </v-radio-group>
        </div>

        <div>
          <v-list-item @click="removeDocument" v-if="thumbnailName">
            <template>
              <v-icon class="icon-file-close" aria-label="Close">
                mdi-close
              </v-icon>
            </template>

            <v-list-item-title>{{ thumbnailName }}</v-list-item-title>
          </v-list-item>
          <v-file-input
            v-else
            prefix
            label="File input"
            @change="handleSelectThumbnail"
            :rules="[required]"
          />
        </div>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            type="submit"
            :loading="loading"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="closeDialog"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
        <AlertErrorComponent :errorData="errorData" v-if="errorData" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { LanguageTextEnum, LanguageEnum } from "@/shared/enums/LanguageEnum";
import { videoPlatformEnum } from "@/shared/enums/VideoPlatformEnum";
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import AlertErrorComponent from "@/components/dxa/AlertError/AlertErrorComponent.vue";

export default {
  name: "AddOrEditVideoDialog",
  components: {
    AlertErrorComponent,
  },
  data() {
    return {
      selectedLanguage: LanguageTextEnum.PT,
      apiService: new ApiService(),
      gs: new GeneralServices(),
      videoPlatformEnum,
      languageOptions: [
        {
          text: "PT",
          value: LanguageEnum.pt,
        },
        {
          text: "EN",
          value: LanguageEnum.en,
        },
      ],
      loading: false,
      thumbnailName: null,
      videoObject: {
        index: null,
        language: null,
        title: null,
        link: null,
        thumbnail: null,
        platform: null,
      },
      videosByLanguage: [],
      indexLoading: false,
      videosIndexOptions: [],
      errorData: null,
    };
  },
  created() {
    this.videosByLanguage = this.videos;
    this.indexOptions();
    this.handleSelectCurrentFileIndex();

    if (this.editVideo) {
      this.videoObject.index = this.videoToEdit.index;
      this.videoObject.language = this.videoToEdit.language;
      this.videoObject.title = this.videoToEdit.file;
      this.videoObject.link = this.videoToEdit.videoId;
      this.videoObject.platform = this.videoToEdit.videoPlatform;
      this.thumbnailName = this.videoToEdit.thumbFileName;
    }
  },
  props: {
    videos: Array,
    videoToEdit: Object,
    editVideo: Boolean,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    indexOptions() {
      let i;
      let returnList = [];
      for (i = 1; i <= this.videosByLanguage.length; i++) {
        returnList.push(i);
      }

      if (!this.editVideo) {
        returnList.push(this.videosByLanguage.length + 1);
      }

      this.videosIndexOptions = returnList;
      this.$forceUpdate();
    },
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      }

      if (!result) {
        this.loading = false;
      }
    },
    handleSelectCurrentFileIndex() {
      if (!this.editVideo) {
        this.videoObject.index = this.videos.length + 1;
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleSelectThumbnail(e) {
      this.videoObject.thumbnail = e;
    },
    removeDocument() {
      this.thumbnailName = null;
      this.$forceUpdate();
    },
    async getVideosByLanguage(language) {
      this.loading = true;
      this.indexLoading = true;

      await this.apiService
        .getRequest(`b2bmaterials/videos/${language}/${true}`)
        .then((resp) => {
          this.videosByLanguage = resp.content;
          this.indexOptions();

          if (
            this.editVideo &&
            this.videoObject.language !== this.videoToEdit.language
          ) {
            this.videosIndexOptions.push(resp.content.length + 1);
            this.videoObject.index = resp.content.length + 1;
          } else if (
            this.editVideo &&
            this.videoObject.language === this.videoToEdit.language
          ) {
            this.videoObject.index = this.videoToEdit.index;
          }

          if (!this.editVideo) {
            this.videoObject.index = resp.content.length + 1;
          }

          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
      this.indexLoading = false;
    },
    async submit() {
      let payload = {
        index: this.videoObject.index,
        language: this.videoObject.language,
        videoId: this.videoObject.link,
        visible: true,
        videoPlatform: this.videoObject.platform,
        file: this.videoObject.title,
      };

      if (this.videoObject.thumbnail) {
        payload.thumbFileName = this.videoObject.thumbnail.name;
        payload.thumbData = this.videoObject.thumbnail;
      } else {
        payload.thumbFileName = this.videoToEdit.thumbFileName;
        payload.thumbData = null;
      }

      if (this.editVideo) {
        payload.id = this.videoToEdit.id;
      }

      let formData = new FormData();

      for (var key in payload) {
        formData.append(key, payload[key]);
      }

      if (this.editVideo) {
        await this.apiService
          .putRequest(`b2bmaterials/videos`, formData)
          .then((resp) => {
            this.$emit("successVideoRequest");
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            if (err.body.content) {
              this.errorData = err.body.content.file;
            }

            if (err.status !== 400) {
              this.$toast.error(this.$t("an_error_ocurred"));
            }
          });
      } else {
        await this.apiService
          .postRequest(`b2bmaterials/videos`, formData)
          .then((resp) => {
            this.$emit("successVideoRequest");
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            if (err.body.content) {
              this.errorData = err.body.content.file;
            }

            if (err.status !== 400) {
              this.$toast.error(this.$t("an_error_ocurred"));
            }
          });
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.video-platform-title {
  font-family: "Source Sans Pro";
  font-size: 16px;
}
</style>
