<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title>
      <h4 class="dxa_modal_title h4">{{ $t("add_or_edit_file") }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
        <v-select
          :label="$t('index')"
          :items="filesIndexOptions"
          :rules="[required]"
          v-model="fileObject.index"
          outlined
          :loading="indexLoading"
        ></v-select>
        <v-select
          :items="languageOptions"
          :label="$t('language')"
          v-model="fileObject.language"
          :rules="[required]"
          outlined
          item-text="text"
          item-value="value"
          @change="handleChangeLanguage"
        ></v-select>

        <v-select
          :items="typeOptions"
          :label="$t('file_type')"
          v-model="fileObject.type"
          @change="handleChangeType"
          :rules="[required]"
          outlined
          item-text="text"
          item-value="value"
        ></v-select>

        <div>
          <v-list-item @click="removeDocument" v-if="fileName">
            <template>
              <v-icon class="icon-file-close" aria-label="Close">
                mdi-close
              </v-icon>
            </template>

            <v-list-item-title>{{ fileToEdit.name }}</v-list-item-title>
          </v-list-item>
          <v-file-input
            v-else
            prefix
            label="File input"
            @change="handleSelectFile"
            :rules="[required]"
            accept="pdf/*"
          />
        </div>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            type="submit"
            :loading="loading"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="closeDialog"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
        <AlertErrorComponent :errorData="errorData" v-if="errorData" />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { LanguageTextEnum, LanguageEnum } from "@/shared/enums/LanguageEnum";
import { B2bMaterialFileTypeEnum } from "@/shared/enums/B2bMaterialFileEnum";
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import AlertErrorComponent from "@/components/dxa/AlertError/AlertErrorComponent.vue";

export default {
  name: "AddOrEditFileDialog",
  data() {
    return {
      apiService: new ApiService(),
      fileName: null,
      gs: new GeneralServices(),
      B2bMaterialFileTypeEnum,
      languageOptions: [
        {
          text: "PT",
          value: LanguageEnum.pt,
        },
        {
          text: "EN",
          value: LanguageEnum.en,
        },
      ],
      typeOptions: [
        {
          text: "NewsLatter",
          value: B2bMaterialFileTypeEnum.Newsletters,
        },
        {
          text: "Dealboard Library",
          value: B2bMaterialFileTypeEnum.Dealboard_Library,
        },
      ],
      loading: false,
      fileObject: {
        index: null,
        language: null,
        file: null,
        type: null,
      },
      filesIndexOptions: [],
      filesByLanguage: [],
      indexLoading: false,
      errorData: null,
    };
  },
  components: {
    AlertErrorComponent,
  },
  created() {
    // iniciando o componente com o idioma e o tipo do arquivo selecionado no componente pai.
    // ele pode alterar esses campos a qualquer momento.
    if (!this.editFile) {
      const literal = {
        [0]: this.B2bMaterialFileTypeEnum.Newsletters,
        [1]: this.B2bMaterialFileTypeEnum.Dealboard_Library,
      };
      const fileType = literal[this.currentTab];
      this.fileObject.type = fileType;
      this.fileObject.language = this.currentLanguage;
    }

    this.filesByLanguage = this.files;
    this.indexOptions();
    this.handleSelectCurrentFileIndex();

    if (this.editFile) {
      this.fileObject.index = this.fileToEdit.index;
      this.fileObject.language = this.fileToEdit.language;
      this.fileName = this.fileToEdit.name;
      this.fileObject.type = this.fileToEdit.type;
    }
  },
  props: {
    files: Array,
    fileToEdit: Object,
    editFile: Boolean,
    currentTab: Number,
    currentLanguage: Number,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    handleSelectCurrentFileIndex() {
      if (!this.editFile) {
        this.fileObject.index = this.files.length + 1;
      }
    },
    indexOptions() {
      let i;
      let returnList = [];
      for (i = 1; i <= this.filesByLanguage.length; i++) {
        returnList.push(i);
      }

      if (!this.editFile) {
        returnList.push(this.filesByLanguage.length + 1);
      }

      this.filesIndexOptions = returnList;
      this.$forceUpdate();
    },
    async checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        await this.submit();
      }

      if (!result) {
        this.loading = false;
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleSelectFile(e) {
      this.fileObject.file = e;
    },
    removeDocument() {
      this.fileName = null;
      this.$forceUpdate();
    },
    async handleChangeLanguage(language) {
      await this.handleChangeGetFiles(language, this.fileObject.type);
    },
    async handleChangeType(type) {
      await this.handleChangeGetFiles(this.fileObject.language, type);
    },
    async handleChangeGetFiles(language, fileType) {
      this.indexLoading = true;
      this.loading = true;

      await this.apiService
        .getRequest(`b2bmaterials/files/${language}/${fileType}`)
        .then((resp) => {
          // atualizando o total de arquivos assim que ocorre a troca de idioma
          this.filesByLanguage = resp.content;
          this.indexOptions();

          if (
            this.editFile &&
            this.fileObject.language !== this.fileToEdit.language
          ) {
            this.filesIndexOptions.push(resp.content.length + 1);
            this.fileObject.index = resp.content.length + 1;
          } else if (
            this.editFile &&
            this.fileObject.language === this.fileToEdit.language
          ) {
            this.fileObject.index = this.fileToEdit.index;
          }

          if (!this.editFile) {
            this.fileObject.index = resp.content.length + 1;
          }

          this.$forceUpdate();
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });

      this.loading = false;
      this.indexLoading = false;
    },
    async submit() {
      this.errorData = null;

      let payload = {
        index: this.fileObject.index,
        language: this.fileObject.language,
        type: this.fileObject.type,
      };

      if (this.fileObject.file) {
        payload.fileData = this.fileObject.file;
        payload.name = this.fileObject.file.name;
      } else {
        payload.name = this.fileToEdit.name;
        payload.fileData = null;
      }

      if (this.editFile) {
        payload.id = this.fileToEdit.id;
      }

      // criando o formData
      let formData = new FormData();

      for (var key in payload) {
        formData.append(key, payload[key]);
      }

      if (this.editFile) {
        await this.apiService
          .putRequest("b2bmaterials/files", formData)
          .then((resp) => {
            this.$emit("successFileRequest", payload);
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            if (err.body.content) {
              this.errorData = err.body.content.name;
            }

            if (err.status !== 400) {
              this.$toast.error(this.$t("an_error_ocurred"));
            }
          });
      } else {
        await this.apiService
          .postRequest("b2bmaterials/files", formData)
          .then((resp) => {
            this.$emit("successFileRequest", payload);
            this.$toast.success(this.$t("saved"));
          })
          .catch((err) => {
            if (err.body.content) {
              this.errorData = err.body.content.name;
            }

            if (err.status !== 400) {
              this.$toast.error(this.$t("an_error_ocurred"));
            }
          });
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
