<template>
  <section>
    <MaterialsB2BVideoComponent />
    <MaterialsB2BFilesComponent />
  </section>
</template>

<script>
import MaterialsB2BVideoComponent from "./components/MaterialsB2BVideos/MaterialsB2BVideoComponent.vue";
import MaterialsB2BFilesComponent from "./components/MaterialsB2BFiles/MaterialsB2BFilesComponent.vue";

export default {
  name: "MaterialsB2B",
  components: {
    MaterialsB2BFilesComponent,
    MaterialsB2BVideoComponent,
  },
};
</script>

<style scoped></style>
